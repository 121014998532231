@import 'bootstrap/scss/bootstrap.scss';
@import "./_variables.module.scss";

html,
body {
	padding: 0;
	margin: 0;
	font-family: nunito, Arial, Helvetica, sans-serif;
}

* {
	box-sizing: border-box;
}

a,.btn-link {
	text-decoration: none;
}
h1 {
	@include title1;
}

h2 {
	@include title2;
}

h3 {
	@include title3;
}

h4 {
	@include title4;
}

h5 {
	@include title5;
}

h6 {
	@include title6;
}
body,
.body {
	font-size: $font-size-s;
	color: $neutral-900-value;
}
.text-body-m-400 {
	font-size: $font-size-m;
	font-weight: 400;
}
.text-body-m-600 {
	font-size: $font-size-m;
	font-weight: 600;
}
.text-body-s-400 {
	font-size: $font-size-s;
	font-weight: 400;
}
.text-body-s-600 {
	font-size: $font-size-s;
	font-weight: 600;
}
.text-body-xs {
	font-size: $font-size-xs;
	color: $neutral-700-value;
}
.tt1{
	@include title1;
}
.tt2{
	@include title2;
}
.tt3{
	@include title3;
}
.tt4{
	@include title4;
}
.subtt {
	@include title3;
	color: $brand-primary-500-value;
}
.subtt2 {
	font-size: $font-size-base;
	color: $brand-primary-500-value;
	font-weight: 600;
	margin: $spacer * 1.5 0;
}

.fondoGris{
	background-color: $grisClaro;
}
.card{
	border: 0;
	box-shadow: 0px 0px 10px rgba(112, 144, 176, 0.1)
}

// utilitarias toctoc
.bg-neutral-200{
	background-color: $neutral-200-value;
}

// estilos generales para slider

.swiper-pagination-bullet-active {
	background: $AzulPrincipal !important;
}

.swiper-button-next,
.swiper-button-prev {
	display: none !important;
	background: #E9F0FB;
	opacity: 0.5;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
	width: 48px !important;
	height: 48px !important;
	border-radius: 50%;
	color: $AzulPrincipal !important;

	&:after {
		font-weight: bold;
		font-size: 22px !important;
	}

	&:hover {
		opacity: 1;
	}
}

.swiper-wrapper {
	width: 100vw !important;
}

.swiper-button-disabled {
	display: none !important;
}

.customSliderCard {
	padding: 5px !important;
}


.swiper {
	display: flex;
	flex-flow: column;
}

.swiper-pagination {
	order: 1;
	position: static !important;
	margin-top: $espaciado-16;
}

@media (min-width: 768px) {

	.swiper-button-next,
	.swiper-button-prev {
		display: flex !important;
	}

	.swiper-button-disabled {
		display: none !important;
	}

	.swiper-pagination {
		margin-top: $espaciado-24;
	}
}

.swiper-wrapper .swiper-slide {
	height: auto;
}

.swiper-slide {
	.card {
		height: 100%;
	}
}
// BLOQUES
.bl {
	padding: 2rem 0;
	.ttbl {
		font-size: $font-size-l;
		color: $brand-primary-500-value;
		text-align: center;
		margin-bottom: 1.25rem;
		font-weight: 600;
	}
	.subttbl{
		font-size: $font-size-m;
		text-align: center;
		margin-bottom: 1.25rem;
	}
}


@media (min-width: 576px) {
	.bl{
		.ttbl {
			font-size: 2rem;
		}
		.subttbl{
			font-size: $font-size-l;
		}
	}
}

// Listado con checks
.list-check{
	list-style: none;
	padding: 0;
	margin-bottom: 0;
	li{
		display: flex;
		&::before{
			display: inline-block;
			flex-shrink: 0;
			content: "";
			height: 14px;
			width: 14px;
			margin-top: 4px;
			background: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 14 14' enable-background='new 0 0 14 14' xml:space='preserve'%3E%3Cpath fill='none' stroke='%23F05C89' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M12.3,3L5,10.3L1.7,7'/%3E%3C/svg%3E") no-repeat;
			margin-right: .5rem;
		}
	}
}

/* Card icono */
.head-ico{
	display: flex;
	align-items: center;
	background-color: transparent;
	border-bottom: 0;
	padding-top: 1rem;
	.card-title {
		font-size: 1rem;
		color: $brand-primary-500-value;
		font-weight: 700;
		margin-bottom: 0;
		
	}
	.ico{
		margin-right: 1rem;
	}
	
}

/* Card MÓDULOS */
.card-modulo{
	border: 0;
	box-shadow: 0px 4px 14px rgba(112, 144, 176, 0.1);
	margin-bottom: 1.5rem;
	height: 100%;
	.card-header{
		border: 0;
		background-color: $neutral-200-value;
		padding: 1rem 1rem 0 1rem;
		border-radius: 0.625rem;
		img{
			width: 120px;
			height: auto;
			margin: auto;
			display: block;
		}
	}
	.tt{
		color: $brand-primary-500-value;
		font-weight: 700;
		font-size: $font-size-l;
		margin-bottom: 1rem;
	}
}
@media (min-width: 768px) {

	
	.card-modulo {
		.card-header {
			img{
				width: auto;
				height: 70px;
			}
		}
	}
}
@media (min-width: 1200px) {
	.card-modulo {
		.card-header{
			display: flex;
			align-items: center;
			gap: 1rem;
		}
		.tt{
			font-size: 1.5rem;
			flex-shrink: 1;
		}
	}
}

/* Custom 404 */
.main-404{
	background-color: $neutral-200-value;
	padding: $spacer * 2 0;
	display: flex;
}
.cont404 .tt {
    font-size: 4rem;
    color: $brand-primary-500-value;
}


.cont404 .tt {
  font-size: 36px;
  color: #3200c1;
}
.cont404 .subtitle {
  font-size: 24px;
}

.cont404 .description {
  font-size: 18px;
}

.cont404 .subtt {
    font-size: 1.2rem;
}

.color-main {
  color: $brand-primary-500-value;
}

.cont404-text-light {
  color: #666666 !important;
}

.cont404 img {
  max-width: 300px;
}

.cont404 {
    max-width: 700px;
    margin: auto;
    text-align: center;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 3rem;
}

.confundido {
    width: 130px;
    height: auto;
}

@keyframes pestaneo-on {
    0% {
        opacity: 1;
    }
    95% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes pestaneo-off {
    0% {
        opacity: 0;
    }
    95% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.eye-on {
    animation: pestaneo-on 1.5s infinite;
}

.eye-off {
    animation: pestaneo-off 1.5s infinite;
}

@media (min-width: 576px) {
    .cont404 {
        text-align: left;
        margin-top: 5rem;
    }
    .cont404 img {
      max-width: 576px;
    }
    .cont404 .col-sm-4 {
        order: 2;
    }
    .cont404 .col-sm-8 {
        order: 1;
    }
    .cont404 .tt {
        font-size: 6rem;
    }
    .confundido {
        width: 176px;
    }
}