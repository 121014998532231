$neutral-100-value: #F9F9F9;
$neutral-200-value: #f4f4f4;
$neutral-300-value: #e5e5e5;
$neutral-500-value: #b1b1b1;
$neutral-700-value: #666666;
$neutral-900-value: #343a40;
$brand-primary-200-value: #e9f0fb;
$brand-primary-500-value: #3200c1;
$brand-primary-600-value: #2b049b;
$brand-primary-700-value: #24018a;
$brand-primary-800-value: #1c016b;
$brand-primary-900-value: #10003f;
$brand-secondary-300-value: #acfff0;
$brand-secondary-500-value: #37ffdb;
$brand-secondary-700-value: #03dac6;
$semantic-warning-300-value: #fef3d7;
$semantic-warning-500-value: #f9c235;
$semantic-warning-700-value: #dca414;
$semantic-danger-100-value: #FFF3F4;
$semantic-danger-300-value: #f29ba2;
$semantic-danger-500-value: #e63946;
$semantic-danger-700-value: #c51627;
$semantic-success-300-value: #bee7d1;
$semantic-success-500-value: #23ad63;
$semantic-success-700-value: #187844;
$blanco:#FFF;
$spacer: 1rem;
$border-width:1px;
//TIPOGRAFÍA
$font-family-sans-serif: 'Nunito', sans-serif;
%font-titles {
    font-family: $font-family-sans-serif;
    margin: 0;
}
$font-size-base:1rem;
$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.125;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-size-l: $font-size-base * 1.125;//18
$font-size-m: $font-size-base;//16
$font-size-s: $font-size-base * .875;//14
$font-size-xs: $font-size-base * .75;//12

@mixin title1 {
    @extend %font-titles;
    font-size: $h1-font-size;
    line-height: 128%;
    letter-spacing: .2px;
}

@mixin title2 {
    @extend %font-titles;
    font-size: $h2-font-size;
    line-height: 120%;
    letter-spacing: 0;
}

@mixin title3 {
    @extend %font-titles;
    font-size: $h3-font-size;
    line-height: 124%;
    letter-spacing: 0;
}

@mixin title4 {
    @extend %font-titles;
    font-size: $h4-font-size;
    line-height: 132%;
    letter-spacing: .2px;
}

@mixin title5 {
    @extend %font-titles;
    font-size: $h5-font-size;
    line-height: $font-size-base * 1.5;
    letter-spacing: .2px;
}

@mixin title6 {
    @extend %font-titles;
    font-size: $h6-font-size;
    line-height: $font-size-base * 1.5;
    letter-spacing: .2px;
}
@mixin body-lg {
    font-size: $font-size-base * 1.125;
    line-height: auto;
    letter-spacing: .1px;
}
@mixin body-md-400 {
    font-size: $font-size-base;
    line-height: auto;
}
// @mixin body-md-600 {
//     font-size: $font-size-base;
//     line-height: auto;
// 	font-weight: 600;
// }
// @mixin body-sm-400 {
//     font-size: $font-size-base * .875;
// 	line-height: auto;
// }
@mixin body-sm-600 {
    font-size: $font-size-base * .875;
	line-height: auto;
	font-weight: 600;
}

