/* ESTILOS DESKTOP */
@media screen and (min-width: 851px) {
	.delitosMobile-Landing {
		display: none !important;
	}

	.delitos-Landing {
		display: grid;
		grid-template-columns: auto auto auto;
		background-color: #ffffff;
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		height: 81vw;
	}

	.delitos-Contenedor-Landing {
		position: relative;
		background-color: rgba(255, 255, 255, 1);
		overflow-x: hidden;
		width: 95vw;
	}

	/* DETALLE ENCABEZADO */
	.delitos-Encabezado {
		position: absolute;
		background-color: #ffffff;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
		left: 6.5vw;
		height: 28vw;
		width: 82vw;
		/*border: 1px solid green;*/
	}

	.delitos-Encabezado .Titulo {
		position: absolute;
		width: 60%;
		height: 1vw;
		top: 3vw;
		left: 1%;
		font-weight: 700;
		color: rgba(50, 0, 193, 1);
		font-size: 2.4vw;
		letter-spacing: 0;
		line-height: 2.5vw;
	}

	.delitos-Encabezado .SubTitulo {
		position: absolute;
		width: 60%;
		height: 1vw;
		top: 5.7vw;
		left: 1%;
		font-weight: 400;
		color: #343A40;
		font-size: 1vw;
		letter-spacing: 0%;
	}

	.delitos-Encabezado .Parrafos {
		position: absolute;
		top: 10vw;
		width: 43%;
		left: 1%;
		/*border: 1px solid red;*/
	}

	.delitos-Encabezado .Texto {
		width: 100%;
		/*height: 7vw;*/
		font-weight: 400;
		color: #666666;
		font-size: 1vw;
		line-height: 1.5vw;
		text-align: left;
		/*border: 1px solid black;*/
	}

	.delitos-Encabezado .Fondo {
		top: 1vw;
		left: 42vw;
		position: relative;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url('../public/images/landing/fondo_encabezado.png');
		width: 35vw;
		height: 24vw;
	}

	/* DETALLE DESCARGA */
	.delitos-Descarga {
		position: relative;
		display: flex;
		background-color: #F9F9F9;
		width: 100%;
		top: 28vw;
		height: 22vw;
		/*left: 6.5vw;*/
		/*border: 1px solid yellow;*/
	}

	.delitos-Descarga .Titulo {
		position: absolute;
		width: 60%;
		height: 1vw;
		top: 3vw;
		left: 7.2vw;
		font-weight: 700;
		color: #343A40;
		font-size: 1.2vw;
		letter-spacing: 0;
		line-height: 2.5vw;
	}

	.delitos-Descarga .Archivos {
		background-color: #F9F9F9;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: right bottom;
		margin-top: 0;
		height: 100%;
		width: 100%;
		text-align: center;
	}

	.delitos-Descarga .CodigoEtica {
		top: 6vw;
		position: absolute;
		left: 12vw;
		width: 16vw;
		height: 15vw;
		/*border: 1px solid blue;*/
	}

	.delitos-Descarga .ManualCumplimientoTT {
		top: 6vw;
		position: absolute;
		left: 38vw;
		width: 16vw;
		height: 15vw;
		/*border: 1px solid blue;*/
	}

	.delitos-Descarga .ManualLibreCompetencia {
		top: 6vw;
		position: absolute;
		left: 64vw;
		width: 16vw;
		height: 15vw;
		/*border: 1px solid blue;*/
	}

	.delitos-Descarga .Ico {
		top: 1vw;
		position: relative;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url('../public/images/landing/ico_descarga.png');
		width: 16vw;
		height: 6vw;
	}

	.delitos-Descarga .Texto {
		top: 1vw;
		position: relative;
		width: 100%;
		height: 3vw;
		font-weight: 400;
		color: #666666;
		font-size: 0.9vw;
		line-height: 1.2vw;
		text-align: center;
		/*border: 1px solid black;*/
	}

	.delitos-Descarga .Link {
		position: relative;
		display: flex;
		width: 14.5vw;
		height: 3vw;
		align-items: center;
		justify-content: center;
		top: 2vw;
		left: 0.7vw;
		background-color: rgba(50, 0, 193, 1);
		border-radius: 0.3vw;
	}

	.delitos-Descarga .Link .Boton {
		position: relative;
		width: 14.5vw;
		height: 3vw;
		font-weight: 400;
		color: rgba(255, 255, 255, 1);
		font-size: 1vw;
		text-align: center;
		letter-spacing: 0;
		line-height: 3vw;
	}

	/* DETALLE REPORTE */
	.delitos-Reporte {
		top: 29vw;
		position: relative;
		display: flex;
		background-color: #ffffff;
		left: 6.5vw;
		height: 28vw;
		width: 82vw;
		/*left: 6.5vw;*/
	}

	.delitos-Reporte .ContenedorReporte{
		position: relative;
		width: 100%;
		height: 30vw;
	}

	.delitos-Reporte .ContenedorFondo{
		position: relative;
		width: 50%;
		height: 30vw;
		float: left;
	}

	.delitos-Reporte .ContenedorFondo .Fondo {
		top: 1vw;
		position: relative;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url('../public/images/landing/fondo_reporte.png');
		width: 35vw;
		height: 24vw;
	}

	.delitos-Reporte .ContenedorTitulos{
		position: relative;
		width: 50%;
		height: 30vw;
		float: right;
	}

	.delitos-Reporte .ContenedorTitulos .Titulo {
		position: absolute;
		width: 90%;
		height: 3vw;
		top: 1.5vw;
		font-weight: 700;
		color: #343A40;
		font-size: 1.2vw;
		letter-spacing: 0;
		line-height: 2.5vw;
	}

	.delitos-Reporte .ContenedorTitulos .SubTitulo {
		position: absolute;
		width: 90%;
		height: 4vw;
		top: 4.5vw;
		font-weight: 400;
		color: #343A40;
		font-size: 1vw;
		letter-spacing: 0%;
	}

	.delitos-Reporte .ContenedorTitulos .SubParrafo {
		position: absolute;
		width: 90%;
		height: 2vw;
		top: 8.5vw;
		font-weight: 400;
		color: #343A40;
		font-size: 1vw;
		letter-spacing: 0%;
	}

	.delitos-Reporte .ContenedorTitulos .TextoGarantia {
		position: absolute;
		width: 90%;
		height: 2vw;
		top: 11.5vw;
		font-weight: 400;
		color: #343A40;
		font-size: 1vw;
		letter-spacing: 0%;
	}

	.delitos-Reporte .ContenedorTitulos .TextoEmail {
		position: absolute;
		width: 90%;
		height: 2vw;
		top: 14.5vw;
		font-weight: 400;
		color: #343A40;
		font-size: 1vw;
		letter-spacing: 0%;
	}

	.delitos-Reporte .ContenedorTitulos .TextoCorreo {
		position: absolute;
		width: 90%;
		height: 2vw;
		top: 15.6vw;
		font-weight: 400;
		color: #343A40;
		font-size: 1vw;
		letter-spacing: 0%;
	}

	.delitos-Reporte .ContenedorTitulos .Boton {
		position: relative;
		display: flex;
		width: 15vw;
		height: 2.5vw;
		top: 19vw;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		background-color: rgba(50, 0, 193, 1);
		border-radius: 0.3vw;
		border-color: #3200C1;
	}
}

/* ESTILOS MOBILE */
@media screen and (max-width: 850px) {
	.delitos-Landing {
		display: none !important;
	}

	.delitosMobile-Landing {
		display: grid;
		grid-template-columns: auto auto auto;
		background-color: #ffffff;
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		height: 550vw;
	}

	.delitosMobile-Contenedor-Landing {
		position: relative;
		background-color: rgba(255, 255, 255, 1);
		overflow-x: hidden;
		width: 100%;
	}

	/* DETALLE ENCABEZADO */
	.delitosMobile-Encabezado {
		position: absolute;
		background-color: #ffffff;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
		height: 265vw;
		width: 100%;
	}

	.delitosMobile-Encabezado .Titulo {
		position: absolute;
		width: 90%;
		height: 40vw;
		top: 3vw;
		left: 5%;
		right: 5%;
		font-weight: 700;
		color: rgba(50, 0, 193, 1);
		font-size: 12vw;
		letter-spacing: 0;
		line-height: 14vw;
	}

	.delitosMobile-Encabezado .SubTitulo {
		position: absolute;
		width: 90%;
		height: 17vw;
		top: 45vw;
		left: 5%;
		right: 5%;
		font-weight: 400;
		color: #343A40;
		font-size: 5vw;
		letter-spacing: 0%;
		line-height: 9vw;
	}

	.delitosMobile-Encabezado .Parrafos {
		position: absolute;
		top: 75vw;
		width: 90%;
		left: 5%;
		right: 5%;
	}

	.delitosMobile-Encabezado .Texto {
		width: 100%;
		/*height: 7vw;*/
		font-weight: 400;
		color: #666666;
		font-size: 5.3vw;
		line-height: 6.1vw;
		text-align: left;
		/*border: 1px solid black;*/
	}

	.delitosMobile-Encabezado .Fondo {
		top: 210vw;
		width: 90%;
		height: 48vw;
		left: 5%;
		right: 5%;
		position: relative;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url('../public/images/landing/fondo_encabezado.png');
		background-size: contain;
	}

	/* DETALLE DESCARGA */
	.delitosMobile-Descarga {
		position: relative;
		display: flex;
		background-color: #F9F9F9;
		width: 100%;
		top: 265vw;
		height: 143vw;
		/*left: 6.5vw;*/
	}

	.delitosMobile-Descarga .Titulo {
		position: absolute;
		width: 60%;
		height: 5vw;
		top: 3vw;
		left: 5%;
		font-weight: 700;
		color: #343A40;
		font-size: 5vw;
		letter-spacing: 0;
		line-height: 5vw;
	}

	.delitosMobile-Descarga .Archivos {
		background-color: #F9F9F9;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: right bottom;
		margin-top: 0;
		height: 100%;
		width: 100%;
		text-align: center;
	}

	.delitosMobile-Descarga .CodigoEtica {
		top: 15vw;
		position: absolute;
		left: 20%;
		right: 20%;
		width: 60%;
		height: 40vw;
	}

	.delitosMobile-Descarga .ManualCumplimientoTT {
		top: 55vw;
		position: absolute;
		left: 20%;
		right: 20%;
		width: 60%;
		height: 40vw;
	}

	.delitosMobile-Descarga .ManualLibreCompetencia {
		top: 95vw;
		position: absolute;
		left: 20%;
		right: 20%;
		width: 60%;
		height: 40vw;
	}

	.delitosMobile-Descarga .Ico {
		position: relative;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url('../public/images/landing/ico_descarga.png');
		width: 100%;
		height: 20vw;
	}

	.delitosMobile-Descarga .Texto {
		top: 1vw;
		position: relative;
		width: 100%;
		height: 8vw;
		font-weight: 400;
		color: #666666;
		font-size: 3.2vw;
		line-height: 3.5vw;
		text-align: center;
	}

	.delitosMobile-Descarga .Link {
		position: relative;
		display: flex;
		width: 100%;
		height: 8vw;
		align-items: center;
		justify-content: center;
		top: 2vw;
		background-color: rgba(50, 0, 193, 1);
		border-radius: 1vw;
	}

	.delitosMobile-Descarga .Link .Boton {
		position: relative;
		top: 1vw;
		width: 100%;
		height: 6vw;
		font-weight: 400;
		color: rgba(255, 255, 255, 1);
		font-size: 3vw;
		text-align: center;
		letter-spacing: 0;
		line-height: 3vw;
	}

	/* DETALLE REPORTE */
	.delitosMobile-Reporte {
		top: 270vw;
		position: relative;
		display: flex;
		background-color: #ffffff;
		left: 5%;
		right: 5%;
		height: 130vw;
		width: 90%;
		/*left: 6.5vw;*/
	}

	.delitosMobile-Reporte .Titulo {
		position: absolute;
		width: 100%;
		height: 15vw;
		font-weight: 700;
		color: #343A40;
		font-size: 6vw;
		letter-spacing: 0;
		line-height: 7vw;
	}

	.delitosMobile-Reporte .SubTitulo {
		position: absolute;
		width: 100%;
		height: 22vw;
		top: 20vw;
		font-weight: 400;
		color: #343A40;
		font-size: 5.5vw;
		letter-spacing: 0;
		line-height: 7vw;
	}

	.delitosMobile-Reporte .SubParrafo {
		position: absolute;
		width: 100%;
		height: 22vw;
		top: 40vw;
		font-weight: 400;
		color: #343A40;
		font-size: 5.5vw;
		letter-spacing: 0;
		line-height: 7vw;
	}

	.delitosMobile-Reporte .TextoGarantia {
		position: absolute;
		width: 100%;
		height: 22vw;
		top: 65vw;
		font-weight: 400;
		color: #343A40;
		font-size: 5.5vw;
		letter-spacing: 0;
		line-height: 7vw;
	}

	.delitosMobile-Reporte .TextoEmail {
		position: absolute;
		width: 100%;
		height: 7vw;
		top: 90vw;
		font-weight: 400;
		color: #343A40;
		font-size: 5.5vw;
		letter-spacing: 0;
		line-height: 7vw;
	}

	.delitosMobile-Reporte .TextoCorreo {
		position: absolute;
		width: 100%;
		height: 7vw;
		top: 97vw;
		font-weight: 400;
		color: #343A40;
		font-size: 5.5vw;
		letter-spacing: 0;
		line-height: 7vw;
	}

	.delitosMobile-Reporte .Boton {
		position: relative;
		display: flex;
		width: 100%;
		height: 10vw;
		top: 110vw;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		background-color: rgba(50, 0, 193, 1);
		border-radius: 1vw;
		border-color: #3200C1;
	}
}