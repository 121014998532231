$yiq-contrasted-threshold: 200;
$body-color: $neutral-900-value;
// $emphasized-link-hover-darken-percentage: 20%;
// $form-feedback-valid-color: theme-color("info");
// $form-feedback-invalid-color: lighten($red, 30%);
//ESPACIOS
$border-radius: .375rem;
$border-radius-lg: .75rem;
$border-radius-sm: .25rem;
$rounded-pill: $border-radius;

/*main default*/

$theme-color-primary: $brand-primary-500-value;
$theme-text-color-primary: $body-color;
$theme-text-color-secondary: $neutral-700-value;
$theme-text-color-light: lighten($theme-text-color-primary, 40%);
$theme-bg-light: #f5f6fe;
$theme-border-color: lighten($theme-text-color-primary, 60%);
$theme-color-new: $semantic-success-500-value;
$theme-color-count: $semantic-danger-500-value;
$theme-error: $semantic-danger-500-value;
$smoky-white: #f5f5f5;
$theme-success-color: $semantic-success-500-value;
$theme-warning-color:$semantic-warning-500-value;
$theme-info-color: $brand-secondary-500-value;
$theme-danger-color:$semantic-danger-500-value;
$theme-color-pdf: $semantic-danger-500-value;
$theme-color-word: #66a0fd;
$theme-color-ppt: #f4b400;
$theme-color-excel: #0da95f;
$theme-color-video: #935dc1;
$theme-color-zip: $theme-text-color-primary;
$component-active-color: $blanco;
$component-active-bg: $brand-primary-500-value;
//::::LINKS
$link-color: $theme-color-primary;
$link-hover-color: darken($link-color, 20%);
//::::FONDOS
$bg-body:$blanco;
$bg-success-light:lighten($theme-success-color, 55%);
$bg-warning-light:lighten($semantic-warning-300-value, 7%);
$bg-danger-light:lighten($theme-danger-color, 48%);
$border-radius-box: 0.25rem;
$ancho-menu-lateral:200;
$sombra-cajas:0px 5px 10px 0 darken($bg-body, 4%);
$theme-colors: ("cta":$brand-secondary-500-value, "primary": $theme-color-primary, "secondary": $theme-text-color-secondary, "success": $theme-success-color, "danger": $theme-danger-color, "warning": $theme-warning-color, "info": $theme-info-color, );
$bg-particulares: $brand-secondary-300-value;
$bg-corredoras: $semantic-danger-300-value;
$bg-inmobiliarias: #ECE5FF;
//::::FORMS
$border-color: $neutral-500-value;
//$input-height:$spacer * 2.5;
$label-margin-bottom:0;
$input-btn-padding-y:.4375rem; //7px + 2 de borde = 40px
$input-btn-padding-x:1rem;
//:::: Badges
$badge-font-size: $font-size-xs;
//$badge-font-weight:                 $font-weight-bold !default;
$badge-color: $neutral-700-value;
// $badge-padding-y:                   .35em !default;
// $badge-padding-x:                   .65em !default;
// $badge-border-radius:               $border-radius !default;
//::::CARDS
$card-spacer-y:$spacer;
$card-spacer-x:$spacer * 1.5;
//::::TABLAS
$table-cell-padding: $spacer*.5;
$table-cell-padding-sm: $spacer*.25;
//$table-color: $body-color !default;
// $table-bg:                    null !default;
$table-accent-bg:transparent;
// $table-hover-color:           $table-color !default;
// $table-hover-bg:              rgba($black, .075) !default;
// $table-active-bg:             $table-hover-bg !default;
// $table-border-width:          $border-width !default;
$table-border-color: $border-color;
$table-head-bg:$neutral-900-value;
$table-head-color: $neutral-900-value;
$table-th-font-weight: 700;
// $table-dark-color:            $white !default;
// $table-dark-bg:               $gray-800 !default;
// $table-dark-accent-bg:        rgba($white, .05) !default;
// $table-dark-hover-color:      $table-dark-color !default;
// $table-dark-hover-bg:         rgba($white, .075) !default;
// $table-dark-border-color:     lighten($table-dark-bg, 7.5%) !default;
// $table-striped-order:         odd !default;
// $table-caption-color:         $text-muted !default;
// $table-bg-level:              -9 !default;
// $table-border-level:          -6 !default;
$table-striped-bg: $neutral-200-value;

// $alert-bg-level:-1;
// $alert-border-level:-11;
//$alert-color-level:5;
$alert-bg-scale: -95%;
$alert-border-scale:0;
//$alert-color-scale:0;

// scss-docs-start form-input-variables
$input-placeholder-color: $neutral-500-value;
// scss-docs-start input-group-variables
$input-group-addon-bg: $blanco;
///////////////////////////////////////////
//::::MOLÉCULAS
// DROPDOWN
// $dropdown-min-width: 10rem !default;
//$dropdown-padding-x: 0 !default;
$dropdown-padding-y: $spacer * .5;
// $dropdown-spacer: .125rem !default;
// $dropdown-font-size: $font-size-base !default;
// $dropdown-color: $body-color !default;
// $dropdown-bg: $white !default;
// $dropdown-border-color: rgba($black, .15) !default;
// $dropdown-border-radius: $border-radius !default;
// $dropdown-border-width: $border-width !default;
// $dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width) !default;
// $dropdown-divider-bg: $gray-200 !default;
// $dropdown-divider-margin-y: $nav-divider-margin-y !default;
// $dropdown-box-shadow: 0 .5rem 1rem rgba($black, .175) !default;
// $dropdown-link-color: $gray-900 !default;
// $dropdown-link-hover-color: darken($gray-900, 5%) !default;
// $dropdown-link-hover-bg: $gray-200 !default;
// $dropdown-link-active-color: $component-active-color !default;
// $dropdown-link-active-bg: $component-active-bg !default;
// $dropdown-link-disabled-color: $gray-500 !default;
// $dropdown-item-padding-y: .25rem !default;
$dropdown-item-padding-x:$spacer;
// $dropdown-header-color : $gray-600 !default;
// $dropdown-header-padding : $dropdown-padding-y $dropdown-item-padding-x !default;
$ancho-menu-lateral:200px;
//Navs pills
// scss-docs-start nav-variables
$nav-link-padding-y: $spacer * .5;
$nav-link-padding-x: $spacer;
$nav-link-color: $link-color;
$nav-link-hover-color: $link-hover-color;
$nav-link-transition: color .15s ease-in-out,
background-color .15s ease-in-out,
border-color .15s ease-in-out !default;
$nav-link-disabled-color: $neutral-500-value;
$nav-pills-border-radius : $border-radius;
$nav-pills-link-active-color : $component-active-color;
$nav-pills-link-active-bg : $component-active-bg;