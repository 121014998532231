$AzulPrincipal: #3200C1;
$AzulPrincipalHover: #24018A;
$blanco: #FFFFFF;
$negro: #343A40;
$gris: #666666;
$grisClaro: #F4F4F4;
$purpura: #ECE5FF;
$rosa: #FDE8EE;
$verde: #37FFDB;
$verdeHover: #03DAC6;
$colorSecundario: #E9F0FB;
$neutral-300-value: #E5E5E5;
$neutral-500-value: #B1B1B1;

//sombras
$shadow-s: 0px 4px 14px rgba(112, 144, 176, 0.1);
$shadow-m: 0px 8px 17px rgba(112, 144, 176, 0.15);
$shadow-l: 0px 4px 8px rgba(0, 0, 0, 0.25);

//espaciado
$espaciado: 8px;
$espaciado-16: 16px;
$espaciado-24: 24px;
$espaciado-32: 32px;
$espaciado-40: 40px;
$espaciado-48: 48px;
$espaciado-64: 64px;
$espaciado-112: 112px;

//fuentes
$default-font: nunito, Arial, Helvetica, sans-serif;
$font-size-base: 1rem; // 16
$font-size-xs: $font-size-base * .75; //12
$font-size-s: $font-size-base * .875; //14
$font-size-m: $font-size-base; //16
$font-size-l: $font-size-base * 1.125; //18
//titulo secciones
$ttSeccionMobile: 700 18px $default-font;
$ttSeccionDesktop: normal 600 32px $default-font;
$ttItemSeo : normal bold 20px $default-font;
$itemListaSeo : normal normal 20px $default-font;
$ttDescripcionCardMobile: 400 14px $default-font;
$ttDescripcionCardDesktop: 400 16px $default-font;
$ttHuinchaMobile: 600 10px $default-font;
$ttHuinchaTablet: 400 16px $default-font;
$ttHuinchaDesktop: 400 22px $default-font;
$ttClientsMobile: 400 16px $default-font;
$ttClientsDesktop: 400 18px $default-font;
$ttButton: 400 14px $default-font;

//btn card
.btnCta {
	cursor: pointer;
	display: flex;
	justify-content: center;
	text-decoration: none;
	background: $AzulPrincipal;
	color: $blanco;
	border-radius: 4px;
	padding: 8px 0;
	font-family: $default-font;

	&:hover {
		background-color: $AzulPrincipalHover;
		text-decoration: none;
		color: $blanco;
	}
}

.btnOutline {
	cursor: pointer;
	display: flex;
	justify-content: center;
	text-decoration: none;
	background: $blanco;
	color: $AzulPrincipal;
	border: 1px solid $AzulPrincipal;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 8px 0;
	font: normal normal 14px $default-font;
	line-height: 24px;
	text-align: center;

	&:hover {
		border-color: $AzulPrincipalHover;
		background-color: $AzulPrincipalHover;
		text-decoration: none;
		color: $blanco;
	}
}

.btnPublicar {
	background: $verde;
	color: $AzulPrincipal;
	border-radius: 4px;
	padding: 8px 32px;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		background-color: $verdeHover;
	}
}